
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

h1 {
  color: #333;
  text-align: center;
  margin-top: 20px;
}

p {
  text-align: center;
  font-size: 18px;
}

/* News List Styling */
li {
  margin-bottom: 20px;
  line-height: 1.6;
  font-size: 16px;
}

a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

/* index.css */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #171C2C; /* Match the background */
 /* Default text color */
}

